import { useEffect, useRef } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import classes from "./styles/ScrollableTextWithHighlighting.module.css";
import { cn } from "@/lib/utils";

interface ScrollableTextWithHighlightingProps {
  fullText: string;
  textToHighlight?: string;
}

export default function ScrollableTextWithHighlighting({
  fullText,
  textToHighlight,
}: ScrollableTextWithHighlightingProps) {
  const viewport = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!textToHighlight || !viewport.current) return;

    const chunkStartIndex = fullText.indexOf(textToHighlight) ?? -1;
    if (chunkStartIndex !== -1) {
      const targetElement = viewport.current.querySelector(
        `[data-highlighted="true"]`,
      );
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "instant" });
      }
    }
  }, [fullText, textToHighlight]);

  const chunkStartIndex = textToHighlight
    ? (fullText?.indexOf(textToHighlight) ?? -1)
    : -1;

  return (
    <div className="w-full h-full">
      <ScrollArea
        className={cn(classes["scroll-area"], "h-full w-full")}
        ref={viewport}
      >
        <div className={classes["text"]}>
          {chunkStartIndex !== -1 ? (
            <>
              {fullText?.substring(0, chunkStartIndex)}
              <span className={classes["highlight"]} data-highlighted="true">
                {textToHighlight}
              </span>
              {fullText.substring(chunkStartIndex + textToHighlight!.length)}
            </>
          ) : (
            fullText
          )}
        </div>
      </ScrollArea>
    </div>
  );
}
