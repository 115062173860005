import { useAuth0 } from "@auth0/auth0-react";
import Axios, { AxiosHeaders } from "axios";
import { useState } from "react";
import { errorNotification } from "../utils/Notifications";
import { logger } from "../utils/Logger";
import { ApiResponse } from "../models/ApiResponse";
import qs from "qs";
import * as Sentry from "@sentry/react";
import axiosRetry from "axios-retry";

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});
axiosRetry(axios, { retries: 3 });

export const useApiPrivateRequest = <T>(mapper?: (response: any) => T) => {
  const [data, setData] = useState<T | null>(null);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently, logout } = useAuth0();

  const call = async (
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    params?: Record<string, unknown>,
    body?: any,
    headers?: AxiosHeaders,
  ) => {
    try {
      setData(null);
      setIsFailed(false);
      setIsLoading(true);
      const response = await axios.request<ApiResponse>({
        url: url,
        method: method,
        params: params,
        data: body,
        headers: {
          ...headers,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        // This is needed to prevent Axios from throwing an error on non-200 responses
        validateStatus: (_) => true,
        paramsSerializer: (params) => {
          // This is needed to stringify arrays properly
          return qs.stringify(params);
        },
      });

      switch (response.status) {
        case 200:
          if (mapper) {
            const mappedData = mapper(response.data.data);
            setData(mappedData);
          } else {
            setData(response.data.data);
          }
          return response.data.data;
        case 401:
          logout();
          break;
        case 400: {
          const errors = response.data.data as string[];
          errors.forEach((error) => {
            errorNotification(response.data.message, error);
          });
          Sentry.captureMessage("Bad Request", {
            level: "error",
            tags: {
              source: "useApi",
              type: "bad_request",
              url: url,
              method: method,
            },
            extra: {
              errors: errors,
              message: response.data.message,
            },
          });
          setIsFailed(true);
          break;
        }
        case 404:
          errorNotification(
            "Resource requested was not found",
            response.data.message,
          );
          Sentry.captureMessage("Resource Not Found", {
            level: "error",
            tags: {
              source: "useApi",
              type: "not_found",
              url: url,
              method: method,
            },
            extra: {
              message: response.data.message,
            },
          });
          setIsFailed(true);
          break;
        case 403:
          errorNotification(
            "User is not authorized to perform this action",
            response.data.message,
          );
          Sentry.captureMessage("Forbidden", {
            level: "error",
            tags: {
              source: "useApi",
              type: "forbidden",
              url: url,
              method: method,
            },
            extra: {
              message: response.data.message,
            },
          });
          setIsFailed(true);
          break;
        default:
          errorNotification(
            "Unexpected server response",
            response.data.message,
          );
          Sentry.captureMessage(`Unexpected Response: ${response.status}`, {
            level: "error",
            tags: {
              source: "useApi",
              type: "unexpected_response",
              url: url,
              method: method,
              status: response.status.toString(),
            },
            extra: {
              message: response.data.message,
            },
          });
          setIsFailed(true);
          break;
      }
    } catch (error) {
      errorNotification("No connection to the server");
      logger.error(error);
      Sentry.captureException(error, {
        tags: {
          source: "useApi",
          type: "server_connection_error",
        },
      });
      setIsFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, setData, isFailed, isLoading, call };
};
