import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";
import {
  IconFileTypePdf,
  IconFileTypeDoc,
  IconFileTypePpt,
  IconFileTypeXls,
  IconFileTypeHtml,
  IconFile,
  Icon,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import ScrollableTextWithHighlighting from "../../../../shared/components/scrollable-text-highlighting/ScrollableTextWithHighlighting";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { UserContentType } from "@/shared/enums/UserContentType";
import { useGetOneChatUserContent } from "@/features/sources/api/useGetOneChatUserContent";

const FILE_TYPE_ICON_MAP: Record<UserContentType, Icon> = {
  [UserContentType.Pdf]: IconFileTypePdf,
  [UserContentType.Excel]: IconFileTypeXls,
  [UserContentType.Word]: IconFileTypeDoc,
  [UserContentType.PowerPoint]: IconFileTypePpt,
  [UserContentType.Html]: IconFileTypeHtml,
};

export const FileTypeIcon = ({
  type,
  isLargeScreen,
}: {
  type?: UserContentType;
  isLargeScreen?: boolean;
}) => {
  if (!type || !FILE_TYPE_ICON_MAP[type])
    return <IconFile className="text-primary" size={isLargeScreen ? 20 : 18} />;

  const IconComponent = FILE_TYPE_ICON_MAP[type];
  return (
    <IconComponent className="text-primary" size={isLargeScreen ? 20 : 18} />
  );
};

interface SidebarUserContentProps {
  chatId: string;
  contentId: string;
  chunkText?: string;
}

export default function SidebarUserContent({
  chatId,
  contentId,
  chunkText,
}: SidebarUserContentProps) {
  const [open, setOpen] = useState(false);
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);
  const { userContent, getUserContent } = useGetOneChatUserContent();

  useEffect(() => {
    getUserContent(chatId, contentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  return userContent ? (
    <>
      <div onClick={() => setOpen(true)} className="cursor-pointer">
        <div className="flex flex-col gap-1.5 w-full">
          <div className="flex items-center gap-2">
            <FileTypeIcon
              type={userContent.type}
              isLargeScreen={isLargeScreen}
            />
            <span className="flex-1 text-sm font-bold text-primary underline whitespace-normal break-words leading-relaxed">
              {userContent.title}
            </span>
          </div>
        </div>
      </div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent
          className={cn(
            "max-w-[320px] sm:max-w-[646px] lg:max-w-[910px] xl:max-w-[1200px]",
            "p-0",
          )}
        >
          <DialogHeader>
            <DialogTitle className="text-sm font-bold px-4 py-2">
              <div className="flex items-center gap-4">
                <span>{userContent.title}</span>
                {userContent.url && (
                  <a
                    href={userContent.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:text-primary/80 underline"
                  >
                    [Link to original]
                  </a>
                )}
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="px-8 pb-8">
            <ScrollArea className="h-[60vh]">
              <ScrollableTextWithHighlighting
                fullText={userContent.content}
                textToHighlight={chunkText}
              />
            </ScrollArea>
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <p className="text-sm font-bold leading-relaxed break-words">
      Source document not found.
    </p>
  );
}
