import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook } from "../model/Playbook";

export const useAcceptPlaybook = () => {
  const {
    data: acceptedPlaybook,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const acceptPlaybook = async (token: string) => {
    await call(`/playbook/accept/${token}`, "POST");
  };

  return { acceptedPlaybook, acceptPlaybook, isLoading, isFailed };
};
