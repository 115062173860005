import { memo } from "react";
import ChatItem from "./ChatItem";
import { ChatShort } from "../../../shared/models/Chat";

interface ChatItemListProps {
  chats: ChatShort[];
  userId?: string;
  onChatClick?: (chat: ChatShort) => void;
  lastChatRef?: (node: HTMLDivElement) => void;
  onChatDelete: (chatId: string) => void;
}

function ChatItemList({
  chats,
  userId,
  lastChatRef,
  onChatClick,
  onChatDelete,
}: ChatItemListProps) {
  return (
    <div className="space-y-4">
      {chats.map((chat, index) => {
        const isLast = index === chats.length - 1;
        return (
          <div
            key={chat.id}
            ref={isLast ? lastChatRef : undefined}
            className="transition-opacity duration-200"
          >
            <ChatItem
              chat={chat}
              isActive={false}
              userId={userId}
              onChatClick={onChatClick}
              resolvedMetadata={chat.resolvedMetadata}
              onChatDelete={onChatDelete}
            />
          </div>
        );
      })}
    </div>
  );
}

export default memo(ChatItemList);
