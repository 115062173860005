import classes from "../styles/Settings.module.css";
import SettingsItem from "./SettingsItem";
import { useGetSettings } from "../api/useGetSettings";
import { useEffect } from "react";
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";
import { useAuth0 } from "@auth0/auth0-react";
import { useHasRole } from "@/shared/hooks/useHasRole";
import { UserRole } from "@/shared/enums/UserRole";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ShieldCheck } from "lucide-react";
import { useAdminView } from "@/shared/hooks/useAdminView";

interface SettingsProps {
  opened: boolean;
  close: () => void;
}

const CopyAccessToken = async (clipboard: any, getAccessTokenSilently: any) => {
  const token = await getAccessTokenSilently();
  clipboard.copy("Bearer " + token);
};

export default function Settings({ opened, close }: SettingsProps) {
  const { settings, getSettings, setSettings } = useGetSettings();
  const { getAccessTokenSilently } = useAuth0();
  const [isAdminView, toggleAdminView] = useAdminView();
  const clipboard = useClipboard({ timeout: 2000 });
  const navigate = useNavigate();
  const isAdmin = useHasRole(UserRole.Admin);

  useEffect(() => {
    if (opened) getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  const handleSettingChange = (key: string, value: string) => {
    setSettings((prevSettings) => {
      if (!prevSettings) return prevSettings;
      const newSettings = [...prevSettings];
      const settingIndex = newSettings.findIndex(
        (setting) => setting.key === key,
      );
      newSettings[settingIndex].value = value;
      return newSettings;
    });
  };

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Dialog open={opened} onOpenChange={(open) => !open && close()}>
      <DialogContent className={classes["modal-content"]}>
        <DialogHeader>
          <DialogTitle>Settings</DialogTitle>
        </DialogHeader>
        <div className={`flex flex-col gap-${isLargeScreen ? "3" : "1.5"}`}>
          {settings &&
            settings.map((setting) =>
              import.meta.env.VITE_QUANTLY_MODE === "DOCS" &&
              (setting.key === "AnalysisMlModel" ||
                setting.key === "DashboardMlModel" ||
                setting.key === "NewsProvider") ? null : (
                <SettingsItem
                  setting={setting}
                  key={setting.label}
                  onChange={handleSettingChange}
                />
              ),
            )}
        </div>
        <div className="flex flex-wrap gap-2 mt-1.5">
          {isAdmin && (
            <>
              <Button
                variant="outline"
                onClick={async () =>
                  await CopyAccessToken(clipboard, getAccessTokenSilently)
                }
              >
                {clipboard.copied
                  ? "Access token copied!"
                  : "Copy JWT access token"}
              </Button>

              <Button
                variant="outline"
                className="gap-2"
                onClick={() => {
                  close();
                  navigate("/admin");
                }}
              >
                <ShieldCheck className="h-4 w-4" />
                Admin Dashboard
              </Button>
            </>
          )}
          {isAdmin && (
            <Button
              variant="outline"
              onClick={() => toggleAdminView(!isAdminView)}
            >
              {isAdminView ? "Disable" : "Enable"} Admin View
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
