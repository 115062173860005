import { CompanyInfo } from "../components/company-info/models/CompanyInfo";
import { CompanyDocumentView } from "./CompanyDocument";

export class ResolvedMetadata {
  useNews: boolean;
  useInternet: boolean;
  companies: CompanyInfo[];
  filings: CompanyDocumentView[];
  transcripts: CompanyDocumentView[];

  get documents(): CompanyDocumentView[] {
    return [...this.filings, ...this.transcripts];
  }

  constructor(response: any) {
    this.useNews = response.useNews ?? false;
    this.useInternet = response.useInternet ?? false;
    this.companies = response.companies ?? [];
    this.filings = response.filings ?? [];
    this.transcripts = response.transcripts ?? [];
  }
}
