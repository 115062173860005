import { UserContentType } from "@/shared/enums/UserContentType";

export class ChatUserContent {
  public id: string;
  public content: string;
  public title: string;
  public url?: string;
  public type?: UserContentType;

  constructor(data: any) {
    this.id = data.id;
    this.content = data.content;
    this.title = data.title;
    if (data.url) {
      this.url = data.url;
    }
    if (data.type) {
      this.type = data.type;
    }
  }
}
