import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useDeleteChat = (chatId: string) => {
  const {
    data: isDeleted,
    call,
    isLoading: isDeleting,
    isFailed: isDeleteFailed,
  } = useApiPrivateRequest<void>();

  const deleteChat = async () => {
    await call(`/chat/${chatId}`, "DELETE");
  };

  return { deleteChat, isDeleted, isDeleting, isDeleteFailed };
};
