import { useLocation, useParams } from "react-router-dom";
import TranscriptBody from "../features/transcripts/TranscriptBody";
import { getChunkIdFromQuery } from "../shared/utils/Strings";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@/components/ui/card";

export default function Transcript() {
  const { transcriptId } = useParams();
  const location = useLocation();
  const chunkId = getChunkIdFromQuery(location.search);

  return (
    <div className="flex h-screen flex-col overflow-hidden">
      <div className="flex-shrink-0 border-b bg-background">
        <div className="container mx-auto max-w-7xl px-8 py-6">
          <div className="flex justify-between items-center">
            <div>
              <div className="flex items-center gap-2">
                <a href="/">
                  <img
                    src="/images/fullLogoDark.svg"
                    alt="Quantly Logo"
                    className="h-6 w-auto"
                  />
                </a>
              </div>
              <p className="text-gray-500 text-base">
                AI-powered investment research assistant
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex-grow flex flex-col overflow-hidden">
        <ScrollArea className="flex-grow overflow-hidden">
          <div className="space-y-6 px-4 py-4">
            <Card className="mx-auto max-w-7xl p-6">
              {transcriptId && (
                <TranscriptBody id={parseInt(transcriptId)} chunkId={chunkId} />
              )}
            </Card>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
