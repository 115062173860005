import { useAcceptPlaybook } from "@/features/playbook/api/useAcceptPlaybook";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function AcceptPlaybook() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { acceptedPlaybook, acceptPlaybook, isLoading, isFailed } =
    useAcceptPlaybook();

  useEffect(() => {
    if (token && !acceptedPlaybook && !isFailed) {
      acceptPlaybook(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (acceptedPlaybook) {
      navigate(`/playbooks/${acceptedPlaybook.id}/edit`);
    }
  }, [acceptedPlaybook, navigate]);

  useEffect(() => {
    if (!isFailed) return;
    navigate("/playbooks");
  }, [isFailed, navigate]);

  return (
    <div>
      {isLoading && <h1>Accepting playbook...</h1>}
      {isFailed && <h1>Failed to accept playbook, returning to home...</h1>}
    </div>
  );
}
