import { MessageSquare, BookOpen, Trash2 } from "lucide-react";
import { useState, useCallback, memo } from "react";
import { useNavigate } from "react-router-dom";
import { ChatShort } from "../../../shared/models/Chat";
import { useRenameChat } from "../api/useRenameChat";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { useDeleteChat } from "../api/useDeleteChat";
import { ResolvedMetadata } from "@/shared/models/ResolvedMetadata";

interface ChatItemProps {
  chat: ChatShort;
  isActive: boolean;
  userId?: string;
  onChatClick?: (chat: ChatShort) => void;
  resolvedMetadata?: ResolvedMetadata;
  onChatDelete: (chatId: string) => void;
}

function ChatItem({
  chat,
  isActive,
  userId,
  onChatClick,
  resolvedMetadata,
  onChatDelete,
}: ChatItemProps) {
  const navigate = useNavigate();
  const { renameChat } = useRenameChat(chat.id);
  const [chatName, setChatName] = useState(chat.input || "Untitled Chat");
  const [newChatName, setNewChatName] = useState(chatName);
  const [isEditing, setIsEditing] = useState(false);
  const isPlaybook = chat.playbookId !== null;
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { deleteChat, isDeleting } = useDeleteChat(chat.id);

  const isNewChatNameValid = useCallback(
    () => newChatName.trim() !== "",
    [newChatName],
  );

  const renameChatHandler = useCallback(async () => {
    if (!isNewChatNameValid() || newChatName.trim() === chatName.trim()) {
      setNewChatName(chatName);
    } else {
      await renameChat(newChatName.trim());
      setChatName(newChatName.trim());
    }
    setIsEditing(false);
  }, [chatName, isNewChatNameValid, newChatName, renameChat]);

  const handleEnterPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        renameChatHandler();
      }
    },
    [renameChatHandler],
  );

  const handleChatItemClick = useCallback(() => {
    if (onChatClick) {
      onChatClick(chat);
    } else if (userId) {
      navigate(`/admin/user/${userId}/chat/${chat.id}`);
    } else if (!isActive) {
      navigate(`/c/${chat.id}`);
    }
  }, [chat, isActive, navigate, onChatClick, userId]);

  const formatDate = useCallback((timestamp: string | number | Date) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  }, []);

  const handleDeleteClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      await deleteChat();
      onChatDelete(chat.id);
      setShowDeleteAlert(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chat.id, onChatDelete],
  );

  return (
    <Card
      className="group hover:shadow-lg transition-all duration-200 border-0 shadow-sm overflow-hidden cursor-pointer"
      onClick={handleChatItemClick}
    >
      <div className="p-6 bg-white">
        {/* Category and Date */}
        <div className="flex items-center justify-between mb-4">
          <div
            className={`flex items-center gap-2 px-3 py-1.5 rounded-full ${
              isPlaybook
                ? "bg-emerald-50 text-emerald-700"
                : "bg-blue-50 text-blue-700"
            }`}
          >
            {isPlaybook ? (
              <BookOpen className="h-4 w-4" />
            ) : (
              <MessageSquare className="h-4 w-4" />
            )}
            <span className="text-sm font-medium">
              {isPlaybook ? "Playbook" : "Chat"}
            </span>
          </div>
          <div>
            <time className="text-sm text-gray-500 mr-2">
              {formatDate(chat.startedAt)}
            </time>
            <Button
              variant="ghost"
              size="icon"
              className="text-red-500 hover:text-red-600"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteAlert(true);
              }}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>

        {/* Main Content */}
        {isEditing ? (
          <Input
            value={newChatName}
            onChange={(e) => setNewChatName(e.target.value)}
            onKeyDown={handleEnterPress}
            onBlur={renameChatHandler}
            autoFocus
            className="w-full mb-4"
          />
        ) : (
          <h3
            className="text-xl font-semibold text-gray-900 mb-4 
                       group-hover:text-gray-700 transition-colors duration-200 
                       line-clamp-2"
          >
            {chatName}
          </h3>
        )}

        <div className="space-y-3">
          {/* Companies */}
          <div className="flex items-center gap-2">
            <span className="text-xs font-medium text-gray-400 uppercase tracking-wider min-w-[80px]">
              Companies
            </span>
            <div className="flex flex-wrap gap-2">
              {resolvedMetadata?.companies?.map((company) => (
                <span
                  key={company.id}
                  className="text-sm bg-gray-100 text-gray-700 px-3 py-1 
                           rounded-full border border-gray-200"
                >
                  {company.companyName}
                </span>
              ))}
              {(!resolvedMetadata?.companies ||
                resolvedMetadata.companies.length === 0) && (
                <span className="text-sm text-gray-400"> - </span>
              )}
            </div>
          </div>

          {/* Documents */}
          <div className="flex items-center gap-2">
            <span className="text-xs font-medium text-gray-400 uppercase tracking-wider min-w-[80px]">
              Sources
            </span>
            <div className="flex flex-wrap gap-2">
              {resolvedMetadata?.documents?.map((doc) => (
                <span
                  key={doc.id}
                  className="text-sm bg-gray-50 text-gray-600 px-3 py-1 
                           rounded-full border border-gray-200"
                >
                  {doc.title}
                </span>
              ))}
              {chat.userContent?.map((content) => (
                <span
                  key={content.id}
                  className="text-sm bg-purple-50 text-purple-600 px-3 py-1 
                           rounded-full border border-purple-200"
                >
                  {content.title}
                </span>
              ))}
              {!resolvedMetadata?.documents?.length &&
                !chat.userContent?.length && (
                  <span className="text-sm text-gray-400"> - </span>
                )}
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div
        className="px-6 py-4 bg-gray-50 border-t border-gray-100 
                    flex justify-between items-center group-hover:bg-gray-100 
                    transition-colors duration-200"
      >
        <div className="flex items-center gap-4">
          <span className="text-sm text-gray-500">
            {chat.metadata.useNews ? "✓ Including News" : "✗ Excluding News"}
          </span>
          <span className="text-sm text-gray-500">
            {chat.metadata.useInternet ? "✓ Web Access" : "✗ No Web Access"}
          </span>
        </div>
        <span className="text-gray-900 text-sm font-medium group-hover:underline">
          Continue Research →
        </span>
      </div>

      <AlertDialog open={showDeleteAlert} onOpenChange={setShowDeleteAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              playbook.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteClick}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
}

export default memo(ChatItem);
